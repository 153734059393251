import { gql } from '@apollo/client';

export default {
  authentication: gql`
    mutation signInUser($email: String!, $password: String!) {
      signInUser(email: $email, password: $password) {
        status
        token
        user {
          id
          displayName
          email
          dealerOrLine5
        }
      }
    }
  `,
  addBankAccount: gql`
    mutation postPaymentMethod(
      $bankTransferName: String!
      $bankTransferRoutingNumber: String!
      $bankTransferRoutingNumberConfirmation: String!
      $bankTransferAccountNumber: String!
      $bankTransferAccountNumberConfirmation: String!
      $bankTransferAccountType: String!
      $autopay: Boolean!
      $formOfPaymentType: String!
      $loanId: Int!
    ) {
      postPaymentMethod(
        bankTransferName: $bankTransferName
        bankTransferRoutingNumber: $bankTransferRoutingNumber
        bankTransferRoutingNumberConfirmation: $bankTransferRoutingNumberConfirmation
        bankTransferAccountNumber: $bankTransferAccountNumber
        bankTransferAccountNumberConfirmation: $bankTransferAccountNumberConfirmation
        bankTransferAccountType: $bankTransferAccountType
        autopay: $autopay
        formOfPaymentType: $formOfPaymentType
        loanId: $loanId
      ) {
        id
      }
    }
  `,
  addCard: gql`
    mutation postPaymentMethod(
      $paymentCardName: String!
      $paymentCardNumber: String!
      $paymentCardType: String!
      $paymentCardCvv: String!
      $paymentCardExpiration: String!
      $autopay: Boolean!
      $formOfPaymentType: String!
      $sameAddress: Boolean!
      $address1: String!
      $address2: String!
      $city: String!
      $state: String!
      $postalCode: String!
      $loanId: Int!
    ) {
      postPaymentMethod(
        paymentCardName: $paymentCardName
        paymentCardNumber: $paymentCardNumber
        paymentCardType: $paymentCardType
        paymentCardCvv: $paymentCardCvv
        autopay: $autopay
        paymentCardExpiration: $paymentCardExpiration
        formOfPaymentType: $formOfPaymentType
        sameAddress: $sameAddress
        address1: $address1
        address2: $address2
        city: $city
        state: $state
        postalCode: $postalCode
        loanId: $loanId
      ) {
        id
      }
    }
  `,
  autoPaySetting: gql`
    mutation autopaySetting($loanId: Int!, $paymentMethodId: Int!) {
      autopaySetting(loanId: $loanId, paymentMethodId: $paymentMethodId) {
        success
      }
    }
  `,

  deletePaymentMethod: gql`
    mutation deletePaymentMethod($loanId: Int!, $paymentMethodId: Int!) {
      deletePaymentMethod(loanId: $loanId, paymentMethodId: $paymentMethodId) {
        success
      }
    }
  `,
  GET_LOANS: gql`
    query loans {
      loans {
        id
        status
        fundedAt
        startDate
        paymentDay
        firstPaymentDate
        balance
        term
        downPayment
        monthlyPayment
        tax
        financedTotal
        currentDueBalance
        pastDueAmount
        daysPastDue
        nextPaymentDate
        paymentPlanType
        nextSplitPaymentDate
        currentAutoDueBalance
        vehicle {
          id
          make
          model
          year
          vin
        }
        house {
          id
          coveredAddress
          numberOfBedrooms
          numberOfBathrooms
          squareFootage
          houseType
          year
        }
      }
    }
  `,
  getPaymentMethods: gql`
    query paymentMethods($loanId: ID!) {
      paymentMethods(loanId: $loanId) {
        id
        lastFour
        transactionType
        cardType
        bankName
        expDate
        amountOverride
        autopay
        cardPaymentThreshold
        cardPaymentProcessingPercentage
        cardPaymentDefaultFee
        cardPromoEnabled
        creditCardPromoCoveredUntilAt
      }
      loans(loanId: $loanId) {
        nextPaymentDate
        monthlyPayment
      }
    }
  `,

  locateAccount: gql`
    query locateAccount($ssn: String!, $dateOfBirth: String!) {
      locateAccount(ssn: $ssn, dateOfBirth: $dateOfBirth) {
        id
        email
        displayName
        customerId
      }
    }
  `,
  getStatements: gql`
    query statements($loanId: ID!) {
      statements(loanId: $loanId) {
        id
        loanId
        date
        createdAt
        paymentDueDate
        encryptedDocumentId
        downloadUrl
      }
      communications(loanId: $loanId) {
        id
        loanId
        title
        createdAt
        pdfGeneratedForPrintingAt
        emailSentAt
        encryptedDocumentId
        downloadUrl
      }
    }
  `,
  getProtections: gql`
    query loanProtections($loanId: ID!) {
      loanProtections(loanId: $loanId) {
        id
        loanId
        name
        price
        startDate
        term
        prorationEstimate
        status
        category
        contract
        providerName
        providerWebsite
        providerPhone
        providerClaimsPhoneNumber
        providerRoadsideAssistanceNumber
      }
      loans(loanId: $loanId) {
        vehicle {
          id
          make
          model
          year
          vin
        }
        house {
          id
          coveredAddress
          numberOfBedrooms
          numberOfBathrooms
          squareFootage
          houseType
          year
        }
      }
    }
  `,
  getCustomerDetail: gql`
    query customer {
      customer {
        prefix
        firstName
        lastName
        suffix
        address1
        address2
        city
        state
        postalCode
        phoneNumber
        cellNumber
        paperStatement
        paperStatementFee
        email
      }
      loans {
        paperStatementFee
      }
    }
  `,
  getPayments: gql`
    query payments($loanId: ID!, $startDate: String!, $endDate: String!) {
      payments(loanId: $loanId, startDate: $startDate, endDate: $endDate) {
        id
        loanId
        appliedDate
        amount
        paymentMethod
        description
        status
        paymentReturnReason
        principal
        interest
        convenienceFees
        lateFees
        nsfFees
        documentFees
        initiatorName
      }
      loans(loanId: $loanId) {
        nextPaymentDate
        monthlyPayment
        paymentPlanType
        nextSplitPaymentDate
        currentAutoDueBalance
      }
    }
  `,
  getCreditCardFee: gql`
     query loans($loanId: ID!) {
      loans(loanId: $loanId) {
        creditCardFee
        cardPromoEnabled
        creditCardPromoCoveredUntilAt
      }
    }
  `,

  getPaymentDetails: gql`
    query loans($loanId: ID!) {
      loans(loanId: $loanId) {
        id
        status
        fundedAt
        startDate
        paymentDay
        firstPaymentDate
        balance
        term
        downPayment
        monthlyPayment
        tax
        financedTotal
        pastDueAmount
        currentDueBalance
        nextPaymentDate
        daysPastDue
        creditCardFee
        paymentPlanType
        nextSplitPaymentDate
        currentAutoDueBalance
      }
      paymentMethods(loanId: $loanId) {
        id
        lastFour
        transactionType
        cardType
        bankName
        expDate
        amountOverride
        autopay
        cardPaymentThreshold
        cardPaymentProcessingPercentage
        cardPaymentDefaultFee
        cardPromoEnabled
        creditCardPromoCoveredUntilAt
      }
    }
  `,
  submitPayment: gql`
    mutation postPayment(
      $loanId: Int!
      $date: String!
      $amount: Float!
      $paymentMethodId: Int!
      $cancelNextScheduledPayment: Boolean!
      $extraPayment: Boolean!
    ) {
      postPayment(
        loanId: $loanId
        date: $date
        amount: $amount
        paymentMethodId: $paymentMethodId
        cancelNextScheduledPayment: $cancelNextScheduledPayment
        extraPayment: $extraPayment
      ) {
        scheduledPayment
        appliedOn
        paymentMethodId
        amount
        principalAndInterestPaid
        fees
        convenienceFee
      }
    }
  `,
  submitNewCardPayment: gql`
    mutation postPayment(
      $loanId: Int!
      $date: String!
      $amount: Float!
      $paymentCardType: String!
      $paymentCardName: String!
      $paymentCardNumber: String!
      $paymentCardCvv: String!
      $paymentCardExpiration: String!
      $autopay: Boolean!
      $formOfPaymentType: String!
      $sameAddress: Boolean!
      $address1: String!
      $address2: String!
      $city: String!
      $state: String!
      $postalCode: String!
    ) {
      postPayment(
        loanId: $loanId
        date: $date
        amount: $amount
        paymentCardName: $paymentCardName
        paymentCardNumber: $paymentCardNumber
        paymentCardType: $paymentCardType
        paymentCardCvv: $paymentCardCvv
        paymentCardExpiration: $paymentCardExpiration
        autopay: $autopay
        formOfPaymentType: $formOfPaymentType
        sameAddress: $sameAddress
        address1: $address1
        address2: $address2
        city: $city
        state: $state
        postalCode: $postalCode
      ) {
        scheduledPayment
      }
    }
  `,
  submitNewAccountPayment: gql`
    mutation postPayment(
      $loanId: Int!
      $date: String!
      $amount: Float!
      $bankTransferName: String!
      $bankTransferRoutingNumber: String!
      $bankTransferRoutingNumberConfirmation: String!
      $bankTransferAccountNumber: String!
      $bankTransferAccountNumberConfirmation: String!
      $bankTransferAccountType: String!
      $autopay: Boolean!
      $formOfPaymentType: String!
    ) {
      postPayment(
        loanId: $loanId
        date: $date
        amount: $amount
        bankTransferName: $bankTransferName
        bankTransferRoutingNumber: $bankTransferRoutingNumber
        bankTransferRoutingNumberConfirmation: $bankTransferRoutingNumberConfirmation
        bankTransferAccountNumber: $bankTransferAccountNumber
        bankTransferAccountNumberConfirmation: $bankTransferAccountNumberConfirmation
        bankTransferAccountType: $bankTransferAccountType
        autopay: $autopay
        formOfPaymentType: $formOfPaymentType
      ) {
        scheduledPayment
      }
    }
  `,
  getLoanById: gql`
    query loans($loanId: Int!) {
      loans(loanId: $loanId) {
        id
        status
        fundedAt
        startDate
        paymentDay
        firstPaymentDate
        balance
        term
        downPayment
        monthlyPayment
        tax
        financedTotal
        currentDueBalance
        pastDueAmount
        
        vehicle {
          id
          make
          model
          year
          vin
        }
        house {
          id
          coveredAddress
          numberOfBedrooms
          numberOfBathrooms
          squareFootage
          houseType
          year
        }
      }
    }
  `,
  getNextScheduledPayment: gql`
    query nextScheduledPayment($loanId: ID!) {
      nextScheduledPayment(loanId: $loanId) {
        id
        customerId
        paymentMethodId
        amount
        processDate
        mailIn
        memo
        archivedAt
        scheduledBy
        status
        cancelledBy
      }
    }
  `,
  getLoanforAccountCard: gql`
    query loans($loanId: ID!) {
      loans(loanId: $loanId) {
        nextPaymentDate
        currentDueBalance
        pastDueAmount
        balance
        paymentPlanType
        nextSplitPaymentDate
        currentAutoDueBalance
        vehicle {
          make
          model
          year
          vin
        }
        house {
          id
          coveredAddress
          numberOfBedrooms
          numberOfBathrooms
          squareFootage
          houseType
          year
        }
      }
    }
  `,

  updateProfile: gql`
    mutation updateCustomer(
      $address_1: String!
      $address_2: String!
      $postalCode: String!
      $city: String!
      $state: String!
      $phoneNumber: String!
      $cellNumber: String!
      $email: String!
      $paperStatement: Boolean!
    ) {
      updateCustomer(
        address_1: $address_1
        address_2: $address_2
        postalCode: $postalCode
        city: $city
        state: $state
        phoneNumber: $phoneNumber
        cellNumber: $cellNumber
        email: $email
        paperStatement: $paperStatement
      ) {
        success
      }
    }
  `,
  fetchLoan: gql`
    query loans($loanId: ID!) {
      loans {
        id
        currentDueBalance
        pastDueAmount
        daysPastDue
        nextPaymentDate
        vehicle {
          make
          model
          year
        }
        house {
          id
          coveredAddress
          numberOfBedrooms
          numberOfBathrooms
          squareFootage
          houseType
          year
        }
      }
      paymentMethods(loanId: $loanId) {
        autopay
      }
    }
  `,

  contactUs: gql`
    mutation contactRequest(
      $subject: String!
      $fullName: String!
      $emailAddress: String!
      $message: String!
      $recaptchaToken: String!
    ) {
      contactRequest(
        subject: $subject
        fullName: $fullName
        emailAddress: $emailAddress
        message: $message
        recaptchaToken: $recaptchaToken
      ) {
        success
      }
    }
  `,
  notificationDetail: gql`
    query paymentMethods($loanId: ID!) {
      paymentMethods(loanId: $loanId) {
        expDate
        autopay
        transactionType
      }
      statements(loanId: $loanId) {
        date
        createdAt
      }
    }
  `,
  forgotPassword: gql`
    mutation forgotPassword($email: String!) {
      forgotPassword(email: $email) {
        success
      }
    }
  `,
  registerAccount: gql`
    mutation registerAccount($ssn: String!, $dateOfBirth: String!, $email: String!) {
      registerAccount(ssn: $ssn, dateOfBirth: $dateOfBirth, email: $email) {
        id
        email
        displayName
        customerId
      }
    }
  `,
  deleteScheduledPayment: gql`
    mutation deleteScheduledPayment($paymentId: Int!) {
      deleteScheduledPayment(paymentId: $paymentId) {
        success
      }
    }
  `,
  resetPassword: gql`
    mutation resetPassword(
      $password: String!
      $passwordConfirmation: String!
      $resetPasswordToken: String!
    ) {
      resetPassword(
        password: $password
        passwordConfirmation: $passwordConfirmation
        resetPasswordToken: $resetPasswordToken
      ) {
        success
        token
      }
    }
  `,
  createLinkToken: gql`
    mutation createPlaidLinkToken(
      $loanId: Int!
    ) {
      createPlaidLinkToken(
        loanId: $loanId
      ) {
        linkToken
        expirationDate
      }
    }
  `,
  registerPlaidPublicToken: gql`
    mutation registerPlaidPublicToken(
      $loanId: Int!
      $publicToken: String!
      $linkToken: String!
    ) {
      registerPlaidPublicToken(
        loanId: $loanId
        publicToken: $publicToken
        linkToken: $linkToken
      ) {
        success
        paymentMethods {
          id
          lastFour
          transactionType
          cardType
          bankName
          expDate
          amountOverride
          autopay
        }
      }
    }
  `,
};
