import {Formik, Form, Field, ErrorMessage} from 'formik';
import React, {useContext, useState, useEffect} from "react";
import Button from "../button/Button";
import LoanDetailContext from "../../context/loanDetailContext";
import {useMutation, useLazyQuery} from "@apollo/client";
import query from "../../query";
import StatusMessage from "../statusMessage/StatusMessage";
import PaymentDetailContext from "../../context/paymentDetailContext";
import Loader from "../loader/Loader";
import cardImg from "../../assets/images/png/creditcards.png";
import {Tooltip} from 'reactstrap';
import cvvImg from "../../assets/images/png/cvv.png";
import './PaymentCardForm.scss';
import SearchLocationInput from "../../pages/userProfile/SearchLocationInput";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { useHistory } from 'react-router-dom';


let cardTypes = {
  '3': 'AMEX',
  '4': 'Visa',
  '5': 'Mastercard',
  '6': 'Discover+Network'
}

const YearOption = () => {
  let rows = [];
  rows.push(
    <option value="" key="" disabled>
      Year
    </option>
  );
  const minYear = new Date().getFullYear();
  const maxYear = new Date().getFullYear() + 9;
  for (let i = minYear; i <= maxYear; i++) {
    rows.push(
      <option value={i} key={i}>
        {i}
      </option>
    );
  }
  return rows;
};

const PaymentCardForm = (props) => {
  const LoanDetail = useContext(LoanDetailContext);
  const history = useHistory();

  const {updatePaymentData, updateMessage, paymentDetail} = useContext(PaymentDetailContext);
  const [loanId, setLoanId] = useState();
  const [autopaySelected, setAutopaySelected] = useState(true);
  const [sameAddress, setSameAddress] = useState(true);
  const [formError, setFormError] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [formWithoutContext, setFormWithoutContext] = useState(false);

  const [creditCardFee, setCreditCardFee] = useState();
  const [cardPromoEnabled, setCardPromoEnabled] = useState();
  const [creditCardPromoCoveredUntilAt, setCreditCardPromoCoveredUntilAt] = useState();
  const [isCreditCardPromoCovered, setCreditCardPromoCovered] = useState();

  const [addPaymentCard, {loading}] = useMutation(query.addCard);
  const [getCreditCardFee, {data}] = useLazyQuery(query.getCreditCardFee, {
    variables: {
      loanId: loanId
    }
  });
  const env = runtimeEnv();
  useEffect(() => {
    getCreditCardFee();
    if (data) {
      setCreditCardFee(data.loans[0]['creditCardFee']);
      setCardPromoEnabled(data.loans[0]['cardPromoEnabled']);
      setCreditCardPromoCoveredUntilAt(data.loans[0]['creditCardPromoCoveredUntilAt']);

      let coveredUntilAt = data.loans[0]['creditCardPromoCoveredUntilAt'];
      if (coveredUntilAt !== null) {
        const [year, month, day] = coveredUntilAt.split('-').map(Number);
        coveredUntilAt = new Date(Date.UTC(year, month - 1, day));
        setCreditCardPromoCovered(coveredUntilAt >= new Date());
      }
    }
  }, [data]);

  const addPaymentManually = (data) => {
    props.addPaymentManually(data)
  }

  useEffect(() => {
    setLoanId(LoanDetail.loan_id);

    if (paymentDetail && Object.keys(paymentDetail).length === 0 && Object.getPrototypeOf(paymentDetail) === Object.prototype) {
      setFormWithoutContext(true);
    }

    if (paymentMethodId) {
      updatePaymentData(prev => ({...prev, paymentMethodId: paymentMethodId}));
      history.push("/MakePayment");
    }
  }, [paymentMethodId, LoanDetail.loan_id, paymentDetail])

  const allowedDesignators = [
    'APT',
    'BLDG',
    'BSMT',
    'LOT',
    'STE',
    'TRLR',
    'UNIT',
    'DEPT',
    'FL',
    'FRNT',
    'HNGR',
    'KEY',
    'LBBY',
    'LOWR',
    'OFC',
    'PH',
    'PIER',
    'REAR',
    'RM',
    'SIDE',
    'SLIP',
    'SPC',
    'STOP',
    'UPPR',
  ];

  const initialValues = {
    loanId: parseInt(LoanDetail.loan_id),
    paymentCardName: '',
    paymentCardNumber: '',
    paymentCardCvv: '',
    paymentCardType: '',
    paymentCardExpiration: '',
    formOfPaymentType: 'payment_card',
    sameAddress: sameAddress,
    address1: '',
    address2: '',
    building: '',
    city: '',
    state: '',
    postalCode: '',
    autopay: autopaySelected,
  }
  const handleLocation = () => {
    debugger
  }
  const toggleTooltip = () => setTooltipOpen(prev => !prev);

  return (
    <div className="col-md-8 col-lg-7 col-12">
      <Formik initialValues={initialValues} onSubmit={(values, {setSubmitting}) => {
        let cardFirstDigit = values.paymentCardNumber.substring(0, 1);
        let paymentCardExpiration = [values.expirationYear, values.expirationMonth, '01'].join('-');
        let formattedValues = {
          ...values,
          loanId: parseInt(values.loanId),
          paymentCardType: cardTypes[cardFirstDigit],
          paymentCardExpiration: paymentCardExpiration
        }

        addPaymentCard({
          variables: formattedValues,
        })
          .then(result => {
            updateMessage('Payment Method Added To Your Account!')
            setSuccessMessage('Payment Method Added To Your Account!')
            setPaymentMethodId(result['data']['postPaymentMethod']['id']);
          })
          .catch(error => {
            if (error) {
              updateMessage(() => error.message);
              setFormError(error.message);
            }
          });
      }}>
        {
          ({values, isValid, dirty, errors, setValues, setFieldValue}) => {

            const autocompleteAddress = (data) => {

              let addressFields = {
                address1: `${data['street_number']} ${data['route']}`,
                city: `${data['locality']}`,
                state: data['administrative_area_level_1'],
                postalCode: data['postal_code']
              }
              setValues(prev => ({...prev, ...addressFields}))
            }

            const validate = (value) => {
              let error;
              if (!value) {
                error = '* required';
              }
              return error;
            }

            const validateCVV = (value) => {
              let error;
              if (!value) {
                error = '* required';
              } else if (value.length < 3) {
                error = 'Must be at least 3 digits';
              }
              return error;
            }

            const validateCardNumber = (value) => {
              let error;
              if (!value) {
                error = '* required';
              }

              if (!Object.keys(cardTypes).includes(value[0])) {
                error = 'Invalid Card Number'
              }
              return error;
            }

            const toggleSameAddress = () => {
              let curr = values.sameAddress;
              setValues(prev => ({...prev, sameAddress: !curr}));
              setSameAddress(!curr);
            }

            const blankIfUndefined = response => response || '';

            const onSelectedAddress = placeInfo => {
              debugger
              if (placeInfo['country'] === 'US') {
                const addressInfo = {
                  address1:
                    blankIfUndefined(placeInfo['street_number']) + ' ' + blankIfUndefined(placeInfo['route']),
                  city: blankIfUndefined(placeInfo['locality']),
                  state: blankIfUndefined(placeInfo['administrative_area_level_1']),
                  postalCode: blankIfUndefined(placeInfo['postal_code']),
                };
                setValues(prev => ({...prev, ...addressInfo}));
              }
            };

            const maskCardNumberInput = e => {
              e.preventDefault();
              const {value} = e.target;
              if (!value) setFieldValue("paymentCardNumber", value);

              const regex = /[0-9]+/;

              if (regex.test(value.toString())) {
                setFieldValue("paymentCardNumber", value);
              }
            }

            return (
              <Form>
                <div className={'container'} id='creditCard'>
                  <div className={'d-flex flex-column'}>
                    <div style={{textAlign: 'center'}}>
                      <img src={cardImg} name="creditcard" style={{maxHeight: '30px', width: 'auto'}} alt="creditcard"/>
                    </div>
                    {creditCardFee && !(cardPromoEnabled && isCreditCardPromoCovered) && <p style={{textAlign: 'center', color: '#a2a2a2', fontWeight: 500}}>
                      {`This payment method adds a convenience fee of $${creditCardFee} to the monthly payment.`}
                    </p>}
                  </div>
                  {loading && <Loader/>}
                  {formError && <StatusMessage color={'danger'} value={formError}/>}
                  {successMessage && <StatusMessage color={'danger'} value={successMessage}/>}
                  <Field type="string"
                         name="paymentCardName"
                         placeholder="Name"
                         className="input-type-text inspectletIgnore no-upscope w-100"
                         validate={validate}/>
                  <ErrorMessage name='paymentCardName' component='div' className={'text-red'}/>
                  <Field type="string"
                         name="paymentCardNumber"
                         placeholder="Card Number"
                         className="input-type-text inspectletIgnore no-upscope w-100"
                         validate={validateCardNumber} onChange={maskCardNumberInput}/>
                  <ErrorMessage name='paymentCardNumber' component='div' className={'text-red'}/>
                  <div className={'row'}>
                    <div className={'col-4'}>
                      <Field type="string"
                             name="expirationMonth"
                             placeholder="Month"
                             as={'select'}
                             className="select-input inspectletIgnore no-upscope w-100"
                             validate={validate}
                      >
                        <option value="">
                          Month
                        </option>
                        <option value="01">Jan 01</option>
                        <option value="02">Feb 02</option>
                        <option value="03">Mar 03</option>
                        <option value="04">Apr 04</option>
                        <option value="05">May 05</option>
                        <option value="06">Jun 06</option>
                        <option value="07">Jul 07</option>
                        <option value="08">Aug 08</option>
                        <option value="09">Sep 09</option>
                        <option value="10">Oct 10</option>
                        <option value="11">Nov 11</option>
                        <option value="12">Dec 12</option>
                      </Field>
                      <ErrorMessage name='expirationMonth' component='div' className={'text-red'}/>
                    </div>
                    <div className={'col-4'}>
                      <Field type="string"
                             name="expirationYear"
                             placeholder="Year"
                             as={'select'}
                             className="select-input inspectletIgnore no-upscope w-100"
                             validate={validate}
                      >
                        <YearOption/>
                      </Field>
                      <ErrorMessage name='expirationYear' component='div' className={'text-red'}/>
                    </div>
                    <div className={'col-4'}>
                      <Field type="string"
                             name="paymentCardCvv"
                             placeholder="CVV"
                             className="input-type-text inspectletIgnore no-upscope w-100"
                             validate={validateCVV}
                      />
                      <strong href="#" id="cvvTooltip">Where is my Code?</strong>
                      <ErrorMessage name='cardCvv' component='div' className={'text-red'}/>
                    </div>
                    <Tooltip placement="top" isOpen={tooltipOpen} target="cvvTooltip" toggle={toggleTooltip}
                             style={{maxWidth: 400}}>
                      <div className="row m-0">
                        <div className="col-8">
                          <strong>Where is my Code?</strong>
                          <p className="m-0">
                            The CID/Security Code is the 3-digit number printed by the
                            signature line on the back of the card.
                          </p>
                        </div>
                        <div className="col-4 p-0">
                          <p className="mb-1">Visa, Mastercard & Discover (3 Digit)</p>
                          <img
                            src={cvvImg}
                            alt="cvv"
                            className="img-fluid"
                            draggable="false"
                          />
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className={'row mt-2'}>
                    <div className={'col-6'}>
                      <strong>Billing Address</strong>
                      <div className={'row'}>
                        <div className={'col-12'}>
                          <label className={'orange-checked'}>
                            <div className={'row'}>
                              <Field type="checkbox" name="sameAddress" onChange={toggleSameAddress}
                                     checked={sameAddress}/>
                              <span className={`${sameAddress && 'orange-checkbox'}`}></span>
                              Use mailing address
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={'col-6'}>
                      <ToggleSwitch isOn={values['autopay']}
                                    name={'autopay'}
                                    handleToggle={() => setValues(prev => ({...prev, autopay: !values['autopay']}))}/>
                    </div>
                  </div>
                  {!values.sameAddress && (
                    <div>
                      <h3>Billing Address</h3>
                      <SearchLocationInput sendLocation={autocompleteAddress}>
                        <Field name="address1" as="string" />
                      </SearchLocationInput>
                      <div className={'row'}>
                        <div className={'col-4'}>
                          <Field name="building" as="select"
                                 className="select-input input-type-text w-250 form-control">
                            <option key={''} val=''>Select</option>
                            {allowedDesignators.map((val, i) => <option key={i} val={val}>{val}</option>)}
                          </Field>
                        </div>
                        <div className={'col-8'}>
                          <Field name="address2" type="string" placeholder="Address2"
                                 className="form-control input-type-text"/>
                        </div>
                      </div>
                      <Field name="city" type="string" placeholder="City" className="input-type-text"/>
                      <Field name="state" type="string" placeholder="State" className="input-type-text"/>
                      <Field name="postalCode" type="string" placeholder="Zip Code" className="input-type-text"/>
                    </div>
                  )}
                  <div className={'row mt-4'}>
                    <Button title={'Back'} onClick={(e) => addPaymentManually({method: '', tab: '1'})}>Back</Button>
                    <Button type="submit" disabled={!isValid} title={formWithoutContext ? 'Submit' : 'Continue'}
                            className={'alert-button text-white'}/>
                  </div>
                </div>
              </Form>
            )
          }
        }
      </Formik>
    </div>
  )
}

export default PaymentCardForm;